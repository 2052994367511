.tacCont {
  font-size: 0px;
}

.tictactoe {
  border: 1px solid #f8f8ff;
  width: 100px;
  height: 100px;
  font-size: 25px;
  background-color: black;
  vertical-align: top;
}

#canvas1 {
  padding-left: 0;
  padding-right: 0;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
