@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}

/* does not seem to be needed for sticky footer
html, body {
  height: 100%;
}
*/

body {
  background-color: #f8f8ff;
}

a {
  text-decoration: none;
  color: black;
}

a:hover {
  cursor: pointer;
  opacity: 0.7;
}

.content {
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.respContainer {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
}

.responsiveIframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.slideshow {
  width: 100%;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slideshowButton {
  height: 50px;
  width: 50%;
  background-color: #00aff5;
  border: none;
  color: #f8f8ff;
  cursor: pointer;
  font-size: large;
}

.textBlock {
  text-align: justify;
}

.caption {
  text-align: center;
  margin-top: 8px;
}

.miscButton {
  height: 50px;
  width: 50%;
  background-color: #00aff5;
  border: none;
  color: #f8f8ff;
  cursor: pointer;
  font-size: large;
}

button:hover {
  opacity: 0.7;
}

/* Social Icons */
.fa {
  padding: 10px;
  width: 100%;
  text-align: center;
  text-decoration: none;
}

.fa:hover {
  opacity: 0.7;
}

.fa-linkedin {
  background: #007bb5;
  color: white;
}

.fa-github {
  background: #6f42c1;
  color: white;
}

.fa-youtube-play {
  background: #bb0000;
  color: white;
}

.fa-instagram {
  background: #125688;
  color: white;
}

.fa-facebook {
  background: #4267b2;
  color: white;
}
/* Social Icons */

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.responsive {
  width: 50%;
  height: auto;
}

table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}

table {
  width: 100%;
}

th,
td {
  padding: 4px;
}

th {
  font-size: 75%;
}

.puzzle {
  text-align: center;
}

.times {
  text-align: right;
}

.contentTitle {
  font-size: 40px;
}

hr {
  height: 2px;
  border-width: 0;
  color: black;
  background-color: black;
}

#contentContainer {
  display: none;
  text-align: center;
}

.interestButton {
  border: none;
  color: #f8f8ff;
  cursor: pointer;
  font-size: large;
}

.interestButton.odd {
  background-color: #16c172;
}

.interestButton.even {
  background-color: #0ccf75;
}

.blue.odd {
  background-color: #00aff5;
}

.blue.even {
  background-color: #0abaff;
}

.row::after {
  content: '';
  clear: both;
  display: table;
}

.col-1 {
  width: 8.33%;
}
.col-2 {
  width: 16.66%;
}
.col-3 {
  width: 25%;
}
.col-4 {
  width: 33.33%;
}
.col-5 {
  width: 41.66%;
}
.col-6 {
  width: 50%;
}
.col-7 {
  width: 58.33%;
}
.col-8 {
  width: 66.66%;
}
.col-9 {
  width: 75%;
}
.col-10 {
  width: 83.33%;
}
.col-11 {
  width: 91.66%;
}
.col-12 {
  width: 100%;
}

[class*='col-'] {
  float: left;
}

#links button,
#links-2 button {
  width: 100%;
  height: 50px;
}

#links,
#links-2 {
  text-align: center;
}

/* Responsiveness */
@media only screen and (max-width: 1600px) {
  .miscButton {
    width: 100%;
  }

  #miscLink {
    background-color: #16c172;
  }
  [class*='col-'] {
    width: 100%;
  }
  .responsive {
    width: 75%;
  }
}

@media only screen and (max-width: 1000px) {
  .content {
    width: 75%;
  }
}

@media only screen and (max-width: 600px) {
  .content p:not(.no-pad),
  .content h1,
  .content h2,
  .content h3 {
    padding-left: 8px;
    padding-right: 8px;
  }
  .content {
    width: 100%;
  }
  .responsive {
    width: 100%;
  }
}
/* Responsiveness */

/* Scrollbar */
/* https://www.w3schools.com/howto/tryit.asp?filename=tryhow_css_custom_scrollbar */

::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  background: #e7e7e7;
}

::-webkit-scrollbar-thumb {
  background: #aaa;
}

::-webkit-scrollbar-thumb:hover {
  background: #777;
}
/* Scrollbar */

.contentPaper {
  margin: 24px;
  background-color: #f8f8ff !important;
}

.tacCont {
  font-size: 0px;
}

.tictactoe {
  border: 1px solid #f8f8ff;
  width: 100px;
  height: 100px;
  font-size: 25px;
  background-color: black;
  vertical-align: top;
}

#canvas1 {
  padding-left: 0;
  padding-right: 0;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

